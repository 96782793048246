import * as React from "react";

import * as Pages from "../../pages";

import HomeIcon from "@material-ui/icons/Home";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import CallcenterIcon from "@material-ui/icons/SettingsPhone";
import SearchIcon from "@material-ui/icons/Search";
import SettingsIcon from '@material-ui/icons/Settings';
import AddCircle from '@material-ui/icons/AddCircle';

const MainMenuItems: MenuItem[] = [

];

export const MenuItems: MenuItem[] = [
	{
		title: "Etusivu",
		path: "/",
		component: Pages.HomePage,
        icon: <HomeIcon />,
	},
	{
		title: "Asiakashaku",
		path: "/search",
		component: Pages.SearchPage,
        icon: <SearchIcon />,
        id: 12,
	},
	{
		title: "Uusi asiakas",
		path: "/newclient",
		component: Pages.CreateClientPage,
        icon: <AddCircle />,
		id: 13,
	},
	{
		title: "Asetukset",
		path: "/settings",
		component: Pages.SettingsPage,
		icon: <SettingsIcon />,
		sub: [
			{
				title: "Käyttäjähallinta",
				path: "/users",
				component: Pages.UsersPage,
				id: 11,
			},
		],
	},
	{
		title: "Kirjaudu ulos",
		path: "/logout",
		component: Pages.LogoutPage,
		hide: true,
	},
	{
		title: "Client",
		path: "/client",
		component: Pages.ClientPage,
		hide: true,
	},
];

export interface MenuItem {
	title: string;
	path: string;
	component: any;
    icon?: JSX.Element;
	id?: number;
	sub?: MenuItem[];
	hide?: Boolean;
}
