export interface User {
    id?: number;
    logged?: boolean;
    username?: string;
    name?: string;
    password?: string;
    //active?: number;
    //customer_id?: number;
    role?: number;
    token?: string;
    token_expires?: number;
    service_access?: ServiceAccess[];
    minutes_left?: number;
    //services?: UserService[];
    //customer: []
    /*
        customer:
        id: 1
        name: "Tannerkoski Capital Oy"
        url: "test-tc-api.magnitude.fi"
    */
};

export interface ServiceAccess {
    id: number;
    value: number;
}

export interface UserLogin{
    username: string;
    password: string;
};

export enum UserActions {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    SETMINUTES = "SETMINUTES",
    //ADD_SERVICES = "ADD_SERVICES",
}

interface UserActionType<T, P> {
    type: T;
    payload: P;
}

export type UserAction =
  | UserActionType<typeof UserActions.LOGIN, User>
  | UserActionType<typeof UserActions.SETMINUTES, User["minutes_left"]>
  //| UserActionType<typeof UserActions.ADD_SERVICES, User["services"][]>
  | UserActionType<typeof UserActions.LOGOUT, null>
;
