
import * as React from "react";
import { makeStyles } from "@material-ui/styles";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button } from "@material-ui/core";

import { IWorkhourReport } from './WorkhourHelpers';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});

interface IWorkhourReports {
    reports: IWorkhourReport[];
    open?: (id?: number) => void;
}

export default function WorkhourReports(props: IWorkhourReports) {
    const classes = useStyles();

    const open = (id?: number) => {
        if (props.open) {
            if (id && id > 0) {
                props.open(id);
            } else {
                props.open();
            } 
        }
    }

    return (
    <Paper className={classes.paper} square={true}>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Typography variant="h6">Kuukausiraportit</Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
                <Button
                    size='small'
                    color="secondary"
                    variant="contained"
                    onClick={() => open()}
                >
                    Uusi raportti
                </Button>
            </Grid>
        </Grid>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Kuukausi</TableCell>
                    <TableCell>Työntekijän nimi</TableCell>
                    <TableCell>Tila</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.reports.map((report: IWorkhourReport, key: number) => {
                        return (
                            <TableRow key={key} onClick={() => open(Number(report.id))}>
                                <TableCell>{report.year} / {report.month}</TableCell>
                                <TableCell>{report.user_name}</TableCell>
                                <TableCell>{report.status_id === 2 ? 'Valmis' : 'Keskeneräinen'}</TableCell>
                            </TableRow>
                        );
                    })
                }    
            </TableBody>
        </Table>
    </Paper>
    );
}