import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button, TextField, TextareaAutosize } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { User } from "../../model/user"; 
import { Client } from "../../model/client";
import { AppConfig } from "../../AppConfig";

import ApiConnection from "../../actions/api/connect";

import fromUnixTime from 'date-fns/fromUnixTime';

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

import { TableWithEditor } from '../../components/TableWithEditor';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});
function Users({
    Cclient,
}: {
    Cclient: Client
}) {
    const classes = useStyles();

    const apiDomain = AppConfig.apiUrl;
    const token =  useSelector((state: RootState) => state.user.token);
	const [UserList, setUserList] = React.useState<User[]>([]);
	const [addUser, setAddUser] = React.useState<User>({});
	const [alert, setAlert] = React.useState<IBasicAlert>({});
    const [info, setInfo] = React.useState<Info>({
        showUsersList: false,
    });


    const apiConnect = new ApiConnection(token);

    function listUsers(){
		
		apiConnect.setValues({
			client_id: Cclient.id,
		});
        apiConnect.connect( 'users/getClientUsers' ).then(data => {
            console.log(data);
			setUserList( data.data );
        })
        .catch( error => {
        });
    }

    function saveUser(user: User){
        if (user.id) {
            apiConnect.setValues({
                id: user.id,
                client_id: Cclient.id,
                name: user.name || '',
                username: user.username || '',
                password: user.password || '',
                password_repeat: user.password || '',
                role: 1,
            });
        } else {
            apiConnect.setValues({
                client_id: Cclient.id,
                name: user.name || '',
                username: user.username || '',
                password: user.password || '',
                password_repeat: user.password || '',
                role: 1,
            });
        }
        apiConnect.connect( 'users/addClientUser' ).then(response => {
            listUsers();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function deleteUser(id: number) {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });

        apiConnect.connect( 'users/delete' ).then(response => {
            listUsers();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    if (info.showUsersList == false) {
        listUsers();
        setInfo({...info, 'showUsersList': true});
    }

    const emptyUser: Object = {
        client_id: Cclient.id,
        name: '',
        username: '',
        password: '',
    };

    return (
        <Paper className={classes.paper} square={true}>
            <TableWithEditor
                title="Käyttäjät"
                noEdit="false"
                columns={[
                    {
                        title: 'ID',
                        id: 'id',
                        hide: true,
                    },
                    {
                        title: 'Nimi',
                        id: 'name',
                        edit: true,
                    },
                    {
                        title: 'Tunnus',
                        id: 'username',
                        edit: true,
                    },
                    {
                        title: 'Salasana',
                        id: 'password',
                        hide: true,
                        edit: true,
                    },
                ]}
                itemData={UserList}
                emptyItem={emptyUser}
                onSave={saveUser}
                onDelete={deleteUser}
            /> 
        </Paper>
    );

};

export default Users;

interface Info{
    showUsersList: any;
};
