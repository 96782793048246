import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
// import { User } from "../../model/user"; 
import { Client } from "../../model/client";
import { AppConfig } from "../../AppConfig";

// import { timeDay, timeMonth } from "d3-time";
// import { timeFormat, timeParse } from "d3-time-format";

// import IconButton from '@material-ui/core/IconButton';
// import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
// import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';

import ApiConnection from "../../actions/api/connect";

import fromUnixTime from 'date-fns/fromUnixTime';

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

// import WorkhoursDay from './Parts/WorkhoursDay';
import WorkhoursMonth from './Parts/WorkhoursMonth';
import WorkhourReports from './Parts/WorkhourReports';
import { IWorkhourReport } from './Parts/WorkhourHelpers';
// import {IWorkDay} from './Parts/WorkhoursDay';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});
function Workhours({
    Cclient,
}: {
    Cclient: Client
}) {

    const [monthReports, setMonthReports] = React.useState<IWorkhourReport[] | undefined>(undefined);
    const [selectedReport, setSelectedReport] = React.useState<number | 'new' | undefined>(undefined);

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    const getReportList = () => {
        apiConnect.setValues({
            client_id: Cclient.id,
        });
        apiConnect.connect( 'ClientWorkMonitoring/list' ).then(response => {
            console.log(response);
            setMonthReports( response );
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    if (!monthReports && Cclient.id) {
        getReportList();
    }
    //const classes = useStyles();
	
    //const [alert, setAlert] = React.useState<IBasicAlert>({});
    
    function openReport(id?: number) {
        if (id) {
            setSelectedReport(id);
        } else {
            setSelectedReport('new');
        }
    }

    function closeReport() {
        setSelectedReport(undefined);
        getReportList();
    }

    let retElement: JSX.Element = <WorkhourReports reports={monthReports ? monthReports : []} open={openReport} />;

    if (Cclient.id && selectedReport) {
        if (selectedReport === 'new') {
            retElement = <WorkhoursMonth 
                client_id={Cclient.id}
                close={closeReport}
            />;
        } else if (selectedReport > 0) {
            const reports = monthReports?.filter((r: IWorkhourReport) => {
                if (r.id === selectedReport) return true;
                return false; 
            });
            if (reports) {
                retElement = <WorkhoursMonth 
                    client_id={Cclient.id} 
                    report={reports ? reports[0] : undefined}
                    close={closeReport}
                />;
                //ClientWorkMonitoring/getWorkList
            }
            
            //console.log(report ? report[0] : 'nob');
        }
        //else
    }

    return retElement;

};

export default Workhours;

interface Info{
    showUsersList: any;
};
