import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@material-ui/core";

import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from "@material-ui/styles";

import 'date-fns';
import * as React from "react";
import { useSelector } from "react-redux";
import ApiConnection from "../../actions/api/connect";
import { history } from "../../configureStore";
import { Client } from "../../model/client";
import { RootState } from "../../reducers/index";
import { AppConfig } from "../../AppConfig";

import CompanyInfo from './Parts/CompanyInfo';
import ClientContactPersons from './Parts/ClientContactPersons';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
    },
    ul: {
        // backgroundColor: 'inherit',
        marginTop: '0rem',
        padding: '0.2rem',
    },
    li: {
        // backgroundColor: 'inherit',
        marginTop: '0rem',
        padding: '0.2rem',
    },
    icon: {
        width: 16,
        height: 16,
    },
});

function Informations({
    Cclient,
    selectTab,
}: {
    Cclient: Client,
    selectTab: (str: string) => void,
}) {
    const classes = useStyles();

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    const [client, setClient] = React.useState<Client>({});
    const apiDomain = AppConfig.apiUrl;

    function getClient() {
        apiConnect.setValues({
			id: Cclient.id,
        });
        apiConnect.connect( 'clients/get' ).then(response => {
            console.log(response);
            setClient( response );
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }


    if (!client.id) getClient();

    if (client.id) {
        return (
            <Paper className={classes.paper} square={true}>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h4">{Cclient.company}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            href={apiDomain+"clients/downloadFile/"+client.id+"/"+client.sha}
                            target="_new"
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            >
                            Luo loppuraportti zip-tiedosto
                        </Button>

                    </Grid>
                    <Grid item xs={12}>
                        <CompanyInfo Cclient={Cclient} />
                    </Grid>
                </Grid>
                <Divider style={{marginTop: "20px", marginBottom: "20px"}} />
                <ClientContactPersons Cclient={Cclient} />
            </Paper>
        );
    } else {
        return (
            <Paper className={classes.paper} square={true}>
                <Typography variant="h6">
                    Haetaan tietoja
                </Typography>
            </Paper>
        );
    }
};

export default Informations;

interface IStatus{
    id: number;
    name: string;
    percent: number;
}
