import * as React from "react";

import ApiConnection from "../../../actions/api/connect";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";

import { Client, ClientContacts } from "../../../model/client";

import { TableWithEditor } from '../../TableWithEditor';

function ClientContactPersons({
    Cclient,
}: {
    Cclient: Client
}) {

    const [ClientContacts, setClientContacts] = React.useState<ClientContacts[] | undefined>(undefined);

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    function listContacts() {
        apiConnect.setValues({
			client_id: Cclient.id,
        });
        apiConnect.connect( 'clientContacts/list' ).then(response => {
            setClientContacts( response );
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function saveContact( contact: ClientContacts ) {
        if (contact.id) {
            apiConnect.setValues({
                id: contact.id,
                client_id: Cclient.id,
                name: contact.name || '',
                title: contact.title || '',
                phone: contact.phone || '',
                email: contact.email || '',
            });
        } else {
            apiConnect.setValues({
                client_id: Cclient.id,
                name: contact.name || '',
                title: contact.title || '',
                phone: contact.phone || '',
                email: contact.email || '',
            });
        }

        apiConnect.connect( 'clientContacts/add' ).then(response => {
            console.log(response);
            listContacts();
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function deleteContact( id: number) {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });

        apiConnect.connect( 'clientContacts/delete' ).then(response => {
            listContacts();   
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    const emptyClientContact = {
        name: '',
        title: '',
        phone: '',
        email: '',
    };

    if (ClientContacts === undefined) listContacts();

    return <TableWithEditor
        title="Kontaktihenkilöt"
        noEdit="false"
        columns={[
            {
                title: 'ID',
                id: 'id',
                hide: true,
            },
            {
                title: 'Nimi',
                id: 'name',
                edit: true,
            },
            {
                title: 'Titteli',
                id: 'title',
                edit: true,
            },
            {
                title: 'Puhelin',
                id: 'phone',
                edit: true,
            },
            {
                title: 'Sähköposti',
                id: 'email',
                edit: true,
            },
        ]}
        itemData={ClientContacts ? ClientContacts : []}
        emptyItem={emptyClientContact}
        onSave={saveContact}
        onDelete={deleteContact}
    />;
}

export default ClientContactPersons;