import * as React from "react";
import DefaultTemplate from "../components/PageTemplates/DefaultTemplate";

import { BasicAlert, IBasicAlert } from '../components/Alerts/BasicAlert';

import { Client } from "../model/client";
import { history } from "../configureStore";

import Informations from "../components/Client/Informations";
import Project from "../components/Client/Project";
import Workhours from "../components/Client/Workhours";
import Filebank from "../components/Client/Filebank";
import Users from "../components/Client/Users";

import ApiConnection from "../actions/api/connect";

import { useSelector } from "react-redux";
import { RootState } from "../reducers/index";

import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Toolbar , Button, Typography, AppBar, Tabs, Tab, Grid  } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
	paper: {
        padding: 20,
	},
});

export function ClientPage() {
	const classes = useStyles();

    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    const [value, setValue] = React.useState('perus');
		const [client, setClient] = React.useState<Client>({});

    const client_id = history && history.location && history.location.state && history.location.state.client_id ? history.location.state.client_id : undefined;
    function handleChange(newValue: any) {
        if ( newValue ) {
            setValue(newValue);
        }
    }

    function getClient(client_id: number) {
        apiConnect.setValues({
            id: client_id,
        });
        apiConnect.connect( 'clients/get' ).then(response => {
            setClient( response );

        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }

    if (!client.id) {
        getClient(client_id);
    }

    const backHome = (): void => {
        handleChange("perus");
    }

    return (
		<DefaultTemplate
			title="Asiakasnäkymä"
			content={
                client && client.id
                ?
                <div>
                    <AppBar position="static">
														<Tabs
                                value={value}
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab value="perus" onClick={() => handleChange("perus")} label="Perustiedot" />
                                <Tab value="tiedot" onClick={() => handleChange("tiedot")} label="Projektin tiedot" />
                                <Tab value="seuranta" onClick={() => handleChange("seuranta")} label="Tuntiseuranta" />
                                <Tab value="pankki" onClick={() => handleChange("pankki")} label="Tiedostopankki" />
                                <Tab value="kayttajat" onClick={() => handleChange("kayttajat")} label="Käyttäjät" />
                            </Tabs>
                    </AppBar>
                    {value == 'perus' && <Informations Cclient={client} selectTab={handleChange}></Informations>}
                    {value == 'tiedot' && <Project Cclient={client}></Project>}
                    {value == 'seuranta' && <Workhours Cclient={client}></Workhours>}
                    {value == 'pankki' && <Filebank Cclient={client}></Filebank>}
                    {value == 'kayttajat' && <Users Cclient={client}></Users>}
                </div>
                :
                <Grid item xs={12}>
                <BasicAlert
                    type="info"
                    message="Haetaan asiakastietoja"
                />
                </Grid>

            }
		/>


    );

}

export default ClientPage;
