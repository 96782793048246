import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button, TextField, TextareaAutosize } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { User } from "../../model/user"; 
import { Client } from "../../model/client";
import { AppConfig } from "../../AppConfig";

import ApiConnection from "../../actions/api/connect";

import fromUnixTime from 'date-fns/fromUnixTime';

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

import { TableWithEditor } from '../../components/TableWithEditor';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});
function Project({
    Cclient,
}: {
    Cclient: Client
}) {
    const classes = useStyles();

    const apiDomain = AppConfig.apiUrl;
    const token =  useSelector((state: RootState) => state.user.token);
    const apiConnect = new ApiConnection(token);

    const [client, setClient] = React.useState<Client>({});

    function getClient() {
        apiConnect.setValues({
			id: Cclient.id,
        });
        apiConnect.connect( 'clients/get' ).then(response => {
            console.log(response);
            setClient( response );
        })
        .catch( error => {
            console.log( 'ERROR: '+error);
        });
    }

    function handleClientSave() {
        apiConnect.setValues({
            id: client.id,
            project_description: client.project_description || '',
            project_implementation_content: client.project_implementation_content || '',
            project_impact_business: client.project_impact_business || '',
        });

        apiConnect.connect( 'Clients/add' ).then(response => {
            console.log(response);
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });

    }
    
    const handleChange = (prop: keyof Client) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setClient({ ...client, [prop]: event.target.value });
    };


    if (!client.id) {
        getClient();
    }    

    if (client.id) {
        return (
            <Paper className={classes.paper} square={true}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Typography variant="h6">Projektin ja loppuraportin kuvaukset</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows="10"
                                    id="project_description"
                                    label="Projektin kuvaus (hakemusteksti)"
                                    onChange={handleChange('project_description')} 
                                    value={client.project_description || ''}
                                />
                            </Grid>
                            <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => handleClientSave() }
                        >
                            Tallenna
                        </Button>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows="10"
                                    id="project_implementation_content"
                                    label="Kerro lyhyesti, mitä projektissa tehtiin ja vertaa toteutusta hyväksyttyyn suunnitelmaan"
                                    onChange={handleChange('project_implementation_content')} 
                                    value={client.project_implementation_content || ''}
                                />
                            </Grid>
                            <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => handleClientSave() }
                        >
                            Tallenna
                        </Button>

                        <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows="10"
                                    id="project_impact_business"
                                    label="Projektin vaikutukset liiketoimintaan"
                                    onChange={handleChange('project_impact_business')} 
                                    value={client.project_impact_business || ''}
                                />
                            </Grid>
                            <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            onClick={ () => handleClientSave() }
                        >
                            Tallenna
                        </Button>


                        </Grid>
                    </Grid>
                </Grid>

            </Paper>
        );
    } else {
        return (
            <Paper className={classes.paper} square={true}>
                <Typography variant="h6">
                    Haetaan tietoja
                </Typography>
            </Paper>
        );
    }

};

export default Project;

interface Info{
    showUsersList: any;
};
