import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button, TextField, TextareaAutosize, Select, MenuItem, InputLabel } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/index";
import { Client, ClientFiles, AddClientFile, AttachmentTypes } from "../../model/client";
import { AppConfig } from "../../AppConfig";

import ApiConnection from "../../actions/api/connect";

import fromUnixTime from 'date-fns/fromUnixTime';

import { BasicAlert, IBasicAlert } from '../../components/Alerts/BasicAlert';

import { TableWithEditor } from '../../components/TableWithEditor';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});
function Filebank({
    Cclient,
}: {
    Cclient: Client
}) {
    const classes = useStyles();

    const apiDomain = AppConfig.apiUrl;
    const token =  useSelector((state: RootState) => state.user.token);
	const [ClientFiles, setClientFiles] = React.useState<ClientFiles[]>([]);
	const [addFile, setAddFile] = React.useState<AddClientFile>({});
	const [Types, setTypes] = React.useState<AttachmentTypes[]>([]);
	const [alert, setAlert] = React.useState<IBasicAlert>({});
    const [info, setInfo] = React.useState<Info>({
        showFilesList: false,
        addFileShow: false,
        removeFileShow: false,
        fileTitle: 'Muuta tiedostoa',
        type_id: 0,
    });

    React.useEffect(() => {
        listFiles(info);
     }, [info]);

    const [filename, setFilename] = React.useState<Files>();

    const apiConnect = new ApiConnection(token);

    function saveFile(newFile: any){
        if (newFile.file) {
            console.log(newFile.file.name);
            var formData = new FormData();
            formData.append('file', newFile.file);
            formData.append("client_id", String(Cclient.id));
            if ( newFile.title && newFile.title !== "" ) {
                formData.append('title', newFile.title);
            } else {
                formData.append('title', newFile.file.name);
            }
            if (info && info.type_id) {
                formData.append("attachment_type_id", String(info.type_id));
            }
            fetch(apiDomain+'ClientAttachments/add', {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer '+token,
                  }),
                body: formData
            }).then(res => {
                if(res.ok) {
                    listFiles(info);
                }
            });
        }
    }

    function deleteFile(id: number) {
        apiConnect.setValues({
			client_id: Cclient.id,
			id: id,
        });
        apiConnect.connect( 'clientAttachments/delete' ).then(response => {
            listFiles(info);
        })
        .catch( error => {
        console.log( 'ERROR: '+error);
        });
    }


    function listFiles(info: Info){
		apiConnect.setValues({
            client_id: Cclient.id,
            attachment_type_id: info.type_id
		});
        apiConnect.connect( 'clientAttachments/list' ).then(data => {
			setClientFiles( data );
        })
        .catch( error => {
        });
    }

    function handleAddFile() {
        console.log("handleAddFile");
        if (filename && filename.file) {
            console.log(filename.file);

            var formData = new FormData();
            formData.append('file', filename.file);
            if (addFile && addFile.title) {
                formData.append('title', addFile.title);
            }
            if (Cclient && Cclient.id) {
                formData.append("client_id", String(Cclient.id));
            }
            if (info && info.type_id) {
                formData.append("attachment_type_id", String(info.type_id));
            }
            fetch(apiDomain+'ClientAttachments/add', {
                method: 'post',
                headers: new Headers({
                    'Authorization': 'Bearer '+token,
                  }),
                body: formData
            }).then(res => {
                if(res.ok) {
                    setAddFile({});
                    listFiles(info);
                }
            });
        }
    }

    function listTypes(){
		apiConnect.setValues({
		});
        apiConnect.connect( 'clientAttachments/listTypes' ).then(data => {
			setTypes( data );
        })
        .catch( error => {
        });
    }

    const handleTypeChange = (prop: keyof Info) => (event: React.ChangeEvent<{ value: any }>) => {
        setInfo({ ...info, [prop]: event.target.value });
    };

    if (info.showFilesList == false) {
        listFiles(info);
        listTypes();
        let user_file = '';
        setInfo({...info, 'showFilesList': true});
    }

    const emptyClientFile: Object = {
        client_id: Cclient.id,
    };

    return (
        <Paper className={classes.paper} square={true}>
            <Grid item xs={4}>
                <InputLabel
                    id="user_id"
                >
                    Tiedostopankin luokka
                </InputLabel>
                <Select id="type_id" value={info.type_id} onChange={handleTypeChange('type_id')}>                        
                    <MenuItem value="0">Kaikki</MenuItem>
                    {
                        Types.map((type: AttachmentTypes, key: number) => {
                            return(
                                <MenuItem key={key} value={type.id}>{type.name}</MenuItem>
                            )
                        })
                    }
                </Select>

            </Grid>

            <div style={{marginTop: "2rem"}} />
            <TableWithEditor
                title="Tiedostot"
                noEdit="false"
                columns={[
                    {
                        title: 'ID',
                        id: 'id',
                        hide: true,
                    },
                    {
                        title: 'Lisätty',
                        id: 'created_at',
                        type: 'datetime'
                    },
                    {
                        title: 'Tiedoston nimi',
                        id: 'title',
                        edit: false,
                    },
                    {
                        title: 'Tiedosto',
                        id: 'file',
                        edit: true,
                        hide: true,
                        type: 'file',
                        typeSettings: {
                            selectedFileName: 'Ei valittua tiedostoa',
                            fileNameId: 'title',
                        },
                    },
                    {
                        title: 'Toiminto',
                        id: 'id',
                        edit: false,
                        type: 'link',
                        url: 'Attachments/downloadFile/attachment',
                        sha: 'sha',
                    },
                ]}
                itemData={ClientFiles}
                emptyItem={emptyClientFile}
                onSave={saveFile}
                onDelete={deleteFile}
            />
        </Paper>
    );

};

export default Filebank;

interface Info{
    showFilesList: any;
    addFileShow: any;
    removeFileShow: any;
    fileTitle: string;
    type_id: any;
};

interface Files {
    file: Blob;
}
