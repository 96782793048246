import { timeFormat } from "d3-time-format";

export const weekDays = ['MA','TI','KE','TO','PE'];

export const months = [
    'Tammikuu',
    'Helmikuu',
    'Maaliskuu',
    'Huhtikuu',
    'Toukokuu',
    'Kesäkuu',
    'Heinäkuu',
    'Elokuu',
    'Syyskuu',
    'Lokakuu',
    'Marraskuu',
    'Joulukuu',
];

export const dayNumber = timeFormat("%u");
export const dayN = timeFormat("%_d");
export const monthN = timeFormat("%_m");
export const year = timeFormat("%Y");
export const fiDate = timeFormat("%_d.%_m.%Y");


export interface IWorkhourReport {
    id?: number;
    client_id: number;
    month: number;
    year: number;
    user_name: string;
    status_id: number;
    created_at?: number;
    modified_at?: number;
}

export interface IDayReport {
    day: number;
    hour: number;
    definition: string;
}

export interface IMonthReport extends IWorkhourReport {
    days: IDayReport[];
}