import * as React from "react";

import { TableCell, TableRow, TextField } from "@material-ui/core";

export interface IWorkDay {
    id: number;
    date: Date;
    day: string;
    hours: number | undefined;
    description: string;
}

interface IWorkDayF extends IWorkDay {
    setHours?: (hours: number, id: number) => void;
    setDescription?: (description: string, id: number) => void;
    uid: number;
}

export default function WorkhoursDay(props: IWorkDayF) {

    const [defSettings, setDefSettings] = React.useState<Boolean>(false);
    const [hours, setHours] = React.useState<number | undefined>(undefined);
    const [description, setDescription] = React.useState<string | undefined>(undefined);

    const resetValues = (h?: number, desc?: string | undefined) => {
        if (h) {
            setHours(Number(h));
        }
        setDescription(desc);
        setDefSettings(true);
    }

    if (defSettings === false) {
        console.log('Reset defSettings: '+props.hours);
        if (props.hours) resetValues(props.hours, props.description);
    }

    const changeDescription = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.setDescription) props.setDescription(event.target.value, props.uid);
        setDescription(event.target.value);
    };

    const changeHours = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.setHours) props.setHours(Number(event.target.value), props.uid);
        if (event.target.value) {
            setHours(Number(event.target.value));
        } else {
            setHours(undefined);
        }
    };

    return (
        <TableRow>
            <TableCell>{props.day}</TableCell>
            <TableCell>
                <TextField
                    // fullWidth
                    type='number'
                    id={'hours-' + props.id}
                    onChange={changeHours()}
                    value={hours}
                />
            </TableCell>
            <TableCell>
                <TextField
                    fullWidth
                    id={'description-' + props.id}
                    onChange={changeDescription()}
                    value={description}
                />
            </TableCell>
        </TableRow>
    );
}

    /*
    
    */

    //console.log(':: ' + defSettings);

    /*const defNumber = props.hours ? Number(props.hours) : undefined;

    const getHours = (hours: number | undefined) => {
        return hours ? Number(hours) : undefined;
    }*/

    //getHours(props.hours)
