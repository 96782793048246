import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, Grid, Button, TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/index";
import { User } from "../../../model/user"; 
import { Client } from "../../../model/client";
import { AppConfig } from "../../../AppConfig";

import { timeDay, timeMonth } from "d3-time";
import { timeFormat, timeParse } from "d3-time-format";

import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import ApiConnection from "../../../actions/api/connect";

import fromUnixTime from 'date-fns/fromUnixTime';

import { BasicAlert, IBasicAlert } from '../../../components/Alerts/BasicAlert';

import WorkhoursDay from './WorkhoursDay';
import {IWorkDay} from './WorkhoursDay';

import {IWorkhourReport, IDayReport, IMonthReport, weekDays, months, dayNumber, dayN, monthN, year, fiDate} from './WorkhourHelpers';

const useStyles = makeStyles({
	paper: {
        padding: 20,
        paddingTop: 15,
	},
	table: {
		width: "100%",
	},
});

interface IWorkMonthE {
    client_id: number;
    report?: IWorkhourReport;
    close?: () => void;
}

interface IDay {
    client_work_monitoring_id: number;
    day: number;
    definition: string;
    hour: number;
    id: number;
}

function WorkhoursMonth(props: IWorkMonthE) {
    const classes = useStyles();

    const apiDomain = AppConfig.apiUrl;
    const token =  useSelector((state: RootState) => state.user.token);
	
    const [alert, setAlert] = React.useState<IBasicAlert>({});
    const [monthStart, setMonthStart] = React.useState<Date | undefined>(undefined);
    const [ready, setReady] = React.useState<Boolean>(false);
    const [userName, setUserName] = React.useState<string | undefined>(undefined);
    const [days, setDays] = React.useState<[] | undefined>(undefined);

    let weekDaysInMonth: Date[] = [];
    const workDays: IWorkDay[] = [];

    function createMonthDays() {
        if (monthStart) {
            //const now = new Date;
            const monthEnd = timeMonth.offset(monthStart,1); //timeDay.offset(timeMonth.offset(monthStart,1),-1);
            //console.log('weekDaysInMonth: '+monthStart.toLocaleDateString()+ ' - ' + monthEnd.toLocaleDateString());
            weekDaysInMonth = timeDay.range(monthStart, monthEnd).filter( (d: Date) => {
                const dNum = Number(dayNumber(d));
                if ( dNum > 0 && dNum < 6) return true;
                return false;
            });

            weekDaysInMonth.map((d: Date) => {
                //console.log(Number(dayN(d)));
                const day: IWorkDay = {
                    id: Number(dayN(d)),
                    date: d,
                    hours: undefined,
                    description: '',
                    day: getDayName(d) + ' ' + fiDate(d),
                };
                if (days) {
                    const dDays = days.filter((d: IDay) => {
                        if (d.day && day.id === d.day) return true;
                    });
                    if (dDays && dDays.length > 0) {
                        const dDay: IDay = dDays[0];
                        day.hours = dDay.hour;
                        day.description = dDay.definition;
                        console.log(day);
                    }
                }
                workDays.push(day);
            });
        }
    }

    if (monthStart === undefined) {
        if (props.report) {
            if (monthStart === undefined) {
                const date = new Date(props.report.year, props.report.month-1, 1);
                setMonthStart(date);
                if (props.report.status_id === 2) setReady(true);
                if (!userName) setUserName(props.report.user_name);
    
                const apiConnect = new ApiConnection(token);
                apiConnect.setValues({
                    id: props.report.id,
                });
                apiConnect.connect( 'ClientWorkMonitoring/getWorkList' ).then(response => {
                    console.log('tWorkList');
                    console.log(response);
                    setDays(response);
                    //createMonthDays();
                })
                .catch( error => {
                    console.log( 'ERROR: '+error);
                });
            }
        } else {
            const now = new Date;
            const start: Date = timeMonth.floor(now);
            setMonthStart(start);
        }
    } else {
        createMonthDays();
    }

    function getDayName(d: Date): string {
        const dNum = Number(dayNumber(d));
        return weekDays[dNum-1];
    }

    function getActMonthName(): string{
        if (monthStart) {
            const mNum = Number(monthN(monthStart));
            return months[mNum-1] + ' ' + year(monthStart);
        }
        return '';
    }

    function changeMonth(change: number) {
        if (monthStart && change) {
            const start: Date = timeMonth.offset(monthStart, change);
            setMonthStart(start);
        }
    }

    function setHours(hours: number, id: number) {
        if (workDays[id]) {
            workDays[id].hours = hours;
            console.log(workDays[id]);
        }
    }

    function setDescription(description: string, id: number) {
        if (workDays[id]) {
            workDays[id].description = description;
            console.log(workDays[id]);
        }
    }

    const changeUserName = () => (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserName( event.target.value );
        console.log(userName);
    }

    function close() {
        if (props.close) props.close();
    }

    function deleteReport() {
        const apiConnect = new ApiConnection(token);
        if (props.report?.id && props.report?.client_id){
            apiConnect.setValues({
                id: props.report.id,
                client_id: props.report.client_id,
            });
            apiConnect.connect( 'ClientWorkMonitoring/delete' ).then(response => {
                if (props.close) props.close();
            })
            .catch( error => {
                console.log( 'ERROR: '+error);
            });
        }
    }

    function save(){
        if (monthStart && userName) {
            const apiConnect = new ApiConnection(token);
            apiConnect.setTokenWithoutContentType(token);

            const days: IDayReport[] = [];
            workDays.map(day => {
                if (day.hours) {
                    console.log(day);
                    const dayReport: IDayReport = {
                        day: day.id,
                        hour: day.hours,
                        definition: day.description,
                    }
                    days.push(dayReport);
                }
            });

            const monthlyReport: IMonthReport = {
                client_id: props.client_id,
                month: Number(monthN(monthStart)),
                year: Number(year(monthStart)),
                status_id: ready ? 2 : 1,
                user_name: userName,
                days: days,
            };

            if (props.report?.id) monthlyReport.id = props.report.id;

            // console.log( monthlyReport );

            apiConnect.setJSONasValue(monthlyReport);

            apiConnect.connect( 'ClientWorkMonitoring/add/' ).then(response => {
                if (props.close) props.close();
                // console.log('jebu');
            })
            .catch( error => {
                console.log( 'ERROR: '+error);
            });
            
        }
    }

    return (
        <Paper className={classes.paper} square={true}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography variant="h6">Kuukausiraportti</Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    {
                        props.report?.id 
                        ? <Button
                            size='small'
                            color='primary'
                            variant='outlined'
                            onClick={() => deleteReport()}
                        >
                            Poista raportti
                        </Button>
                        : ''
                    }
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        id='description'
                        label='Työntekijän nimi'
                        onChange={changeUserName()}
                        defaultValue={userName}
                        //' key={subKey}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="caption">Kuukausi</Typography>
                    <div>
                        <IconButton aria-label="delete" color="primary" onClick={() => changeMonth(-1)}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <div style={{width: '10rem', display: 'inline-block', textAlign: 'center'}}>{getActMonthName()}</div>
                        <IconButton aria-label="delete" color="primary" onClick={() => changeMonth(1)}>
                            <ArrowRightIcon />
                        </IconButton>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell size="small">Päivä</TableCell>
                                <TableCell size="small">Tunnit</TableCell>
                                <TableCell size="medium">Selite</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                               workDays.map((dayProps: IWorkDay, key: number) => {
                                return <WorkhoursDay
                                    key={key}
                                    uid={key}
                                    id={dayProps.id}
                                    date={dayProps.date}
                                    day={dayProps.day}
                                    hours={dayProps.hours}
                                    description={dayProps.description}
                                    setHours={setHours}
                                    setDescription={setDescription}
                                />;
                               })
                            }
                            
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={6}>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                            <Switch
                                checked={!ready}//{state.checkedB}
                                onChange={() => setReady(!ready)}
                                name="checkedB"
                                color="primary"
                            />
                            }
                            label="Tuntiraportti on keskeneräinen"
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} style={{textAlign: "right"}}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => close()}
                    >
                        Peruuta
                    </Button>
                    <Button
                        style={{marginLeft: '1rem'}}
                        color="secondary"
                        variant="contained"
                        disabled={userName && userName!=='' ? false : true}
                        onClick={() => save()}
                    >
                        {
                            ready ? 'Tallenna' : 'Tallenna keskeneräisenä'
                        }
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );

};

export default WorkhoursMonth;
