import { Button, Grid, Paper, Typography, Table, TableHead, TableBody, TableCell, TableRow, Tab, Tabs, AppBar, Toolbar } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import ApiConnection from "../actions/api/connect";
import { User } from "../model/user";

import { history } from "../configureStore";

import TokenTimer from "../components/TokenTimer";

import dateFormat from 'date-fns/format';
import { endOfYesterday } from 'date-fns';
import { isAfter } from 'date-fns';
//import isAfter from 'date-fns/is_after';

import 'date-fns';
//import isAfter from '@date-io/date-fns';
//import endOfYesterday from '@date-io/date-fns';

import DateFnsUtils from '@date-io/date-fns';
import fromUnixTime from 'date-fns/fromUnixTime';
import getUnixTime from 'date-fns/getUnixTime';
import startOfTomorrow from 'date-fns/startOfTomorrow';

export function HomePage() {
	const classes = useStyles();
	const [boxColor, setBoxColor] = React.useState("red");

	const token =  useSelector((state: RootState) => state.user.token);
	const user_id = useSelector((state: RootState) => state.user.id);
    const apiConnect = new ApiConnection(token);
	const [user, setUser] = React.useState<User>({});

	function getUser() {
		apiConnect.setValues({id: user_id
		});
		apiConnect.connect( 'users/getClientUser' ).then(response => {
			if (response.data.role && response.data.role == 1) {
				history.push({
					pathname: '/client',
					state: {client_id: response.data.client_id},
				});					
			}

		})
		.catch( error => {
		console.log( 'ERROR: '+error);
		});
	}

	if (!user.id) {
		getUser();
	}


	return (
		<React.Fragment>
			<Grid container className={classes.root}>
			</Grid>
			<TokenTimer />
		</React.Fragment>
	);

}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		/*height: "100%",*/
		padding: 40,
		maxHeight: '100%',
		overflow: 'scroll',
	},

	paper: {
		padding: 20,
	},

	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},

	/*
	centerContainer: {
		flex: 1,
		height: "90%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},

	button: {
		marginTop: 20,
	},*/
}));
